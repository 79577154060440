<template>
  <transition name="fade">
    <slot></slot>
  </transition>
</template>

<style lang="scss">
.fade-enter-from {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease;
}
.fade-leave-to {
  opacity: 0;
}
</style>
