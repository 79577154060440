<template>
  <img :src="logoUrl" v-if="logoUrl" />
</template>

<script>
export default {
  props: {
    logoUrl: {
      type: [String, Object],
    },
  },
};
</script>
