<template>
  <article class="modal">
    <button class="close" @click="$emit('close')">
      <span class="icon-close" aria-hidden="true"></span>
    </button>
    <section class="modal-body">
      <SanityBlocks :blocks="info.body" />
    </section>
    <footer class="credits">
      <SanityBlocks :blocks="info.footer" />
    </footer>
  </article>
</template>

<script>
import { SanityBlocks } from 'sanity-blocks-vue-component';
export default {
  props: { info: { type: Object, required: true } },
  components: { SanityBlocks },
  setup() {
    const serializers = {
      marks: {
        
      },
    };
    return { serializers };
  }
};
</script>
