<template>
  <div class="navigation-container">
    <nav class="navbar">
      <a
        class="nav-link nav-link--info"
        @click.prevent="navigate('information')"
        >Information</a
      >
      <a :href="instaLink" class="nav-link nav-link--insta" target="_blank"
        >Instagram</a
      >
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    instaLink: String,
  },
  methods: {
    navigate(link) {
      if (link === 'information') {
        this.$emit('show-overlay', true);
      }
    },
  },
};
</script>
