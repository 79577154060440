<template>
  <main class="media">
    <ResponsiveVideoPlayer
      :smallVideoFile="smallVideoFile"
      :largeVideoFile="largeVideoFile"
    />
  </main>
</template>

<script>
import ResponsiveVideoPlayer from '@/components/common/ResponsiveVideoPlayer.vue';

export default {
  components: {
    ResponsiveVideoPlayer,
  },
  data() {
    return {
      smallVideoFile: require('@/assets/video/Yardbird_Animation_Smaller.mp4'),
      largeVideoFile: require('@/assets/video/Yardbird_Animation_Smaller.mp4'),
    };
  },
};
</script>
