<template>
  <video :src="videoFile" autoplay muted loop playsinline></video>
</template>

<script>
export default {
  props: {
    videoFile: {
      type: String,
      required: true,
    },
  },
};
</script>
