import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from "vue-gtag";

import '@/assets/scss/main.scss';

const app = createApp(App)
app.use(router)
if (process.env.NODE_ENV === "production") {
  app.use(VueGtag, {
    config: { id: "G-MSF746M9E3" }
  })
}
app.mount('#app');
