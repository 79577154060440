import sanity from '../../client.js';
import imageUrlBuilder from '@sanity/image-url';

const imageBuilder = imageUrlBuilder(sanity);
const query = `{
  "siteSettings": *[_type == "site"][0] {
    siteName,
    metaDesc,
    "instagram": instagramNew,
    "logo": logo.asset->{
      _id,
      url
    },
  },
  "info": *[_type == "info"][0] {
    body,
    footer,
  }
}`;

export default {
  data() {
    return {
      loading: true,
      posts: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },
    async fetchData() {
      this.error = this.post = null;
      this.loading = true;
      try {
        const posts = await sanity.fetch(query);
        this.loading = false;
        this.posts = posts;
        this.info = posts.info;
        this.insta = posts.siteSettings.instagram;
      } catch (error) {
        this.error = error;
      }
    },
    updateMetaTags(siteName, metaDesc) {
      let descEl = document.querySelector('head meta[name="description"]');
      if (!descEl) {
        descEl = document.createElement('meta');
        descEl.setAttribute('name', 'description');
        document.head.appendChild(descEl);
      }
      document.title = siteName;
      descEl.setAttribute('content', metaDesc);
    },
  },
  watch: {
    posts(newPosts) {
      const { siteName, metaDesc } = newPosts.siteSettings;
      this.updateMetaTags(siteName, metaDesc);
    },
  },
};
