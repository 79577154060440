<template>
  <VideoPlayer :videoFile="selectedVideoFile" />
</template>

<script>
import VideoPlayer from '@/components/common/VideoPlayer.vue';

export default {
  name: 'ResponsiveVideoPlayer',
  components: {
    VideoPlayer,
  },
  props: {
    smallVideoFile: {
      type: String,
      required: true,
    },
    largeVideoFile: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    selectedVideoFile() {
      return this.windowWidth < 960 ? this.smallVideoFile : this.largeVideoFile;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
