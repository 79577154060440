<template>
  <div class="app-container">
    <TheHeader :logoUrl="logoUrl" />
    <router-view />
    <NavbarMenu @show-overlay="onShowOverlay" :instaLink="insta" />
    <FadeTransition>
      <BaseModal v-if="showOverlay" @close="closeOverlay" :info="info" />
    </FadeTransition>
  </div>
</template>

<script>
import globalMixin from '@/components/mixins/globalMixin.js';
import TheHeader from '@/components/layout/TheHeader.vue';
import NavbarMenu from '@/components/layout/NavbarMenu.vue';
import FadeTransition from '@/components/UI/FadeTransition.vue';
import BaseModal from '@/components/UI/BaseModal.vue';

export default {
  name: 'App',
  mixins: [globalMixin],
  components: {
    TheHeader,
    NavbarMenu,
    FadeTransition,
    BaseModal,
  },
  data() {
    return {
      showOverlay: false,
    };
  },
  methods: {
    onShowOverlay(value) {
      this.showOverlay = value;
    },
    closeOverlay() {
      this.showOverlay = false;
    },
  },
  computed: {
    logoUrl() {
      const site = this.posts.siteSettings;
      return site && this.imageUrlFor(site.logo);
    },
  },
};
</script>
