<template>
  <header>
    <LogoImage :logoUrl="logoUrl" />
  </header>
</template>

<script>
import LogoImage from '../common/LogoImage.vue';

export default {
  components: {
    LogoImage,
  },
  props: {
    logoUrl: [String, Object],
    default: null,
  },
};
</script>
