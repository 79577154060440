<template>
  <MainComponent />
</template>

<script>
import globalMixin from '@/components/mixins/globalMixin.js';
import MainComponent from '@/components/pages/MainComponent.vue';

export default {
  name: 'HomeView',
  mixins: [globalMixin],
  components: {
    MainComponent,
  },
};
</script>
